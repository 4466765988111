import axios from 'axios'

const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL

export const LOGIN_URL = `${MIDDLEWARE_URL}/api/user/Login`
export const REGISTER_URL = `${MIDDLEWARE_URL}/api/user/signup`
export const NOTARYREGISTER_URL = `${MIDDLEWARE_URL}/api/user/signup`
// export const MYPRIMARYNOTARY_URL = `${MIDDLEWARE_URL}/api/notary/selectPrimaryNotary`;
export const REQUEST_PASSWORD_URL = `${MIDDLEWARE_URL}/api/user/reset-password`
export const REQUEST_CODE_URL = `${MIDDLEWARE_URL}/api/user/reset-code`
export const REQUEST_CHANGE_PASSWORD_URL = `${MIDDLEWARE_URL}/api/user/new-password`

// export const ME_URL = "api/me";

export function login(email, password, code = '') {
    axios.defaults.withCredentials = true

    return axios.post(
        LOGIN_URL,
        { email, password, code },
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
            },
        }
    )
}

export function register(
    firstName,
    lastName,
    email,
    phone,
    password,
    role,
    refCode = '',
    middleName = '',
    suffix = '',
    createdByNotary = '',
    notaryId = '',
    code
) {
    if (refCode)
        return axios.post(
            REGISTER_URL,
            {
                firstName,
                lastName,
                email,
                phone,
                password,
                role,
                suffix,
                middleName,
                createdByNotary,
                notaryId,
                refCode,
                code,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'true',
                },
            }
        )
    else
        return axios.post(
            REGISTER_URL,
            {
                firstName,
                lastName,
                email,
                phone,
                password,
                role,
                suffix,
                middleName,
                createdByNotary,
                notaryId,
                code,
            },
            { headers: { 'Content-Type': 'application/json' } }
        )
}

export function notaryregister(
    firstName,
    lastName,
    email,
    password,
    state,
    role,
    commissionNo,
    code
) {
    return axios.post(
        NOTARYREGISTER_URL,
        { firstName, lastName, email, password, state, role, commissionNo, code },
        { headers: { 'Content-Type': 'application/json' } }
    )
}

export function psregister(
    firstName,
    lastName,
    email,
    password,
    role,
    commissionNo,
    businessName,
    code
) {
    return axios.post(
        NOTARYREGISTER_URL,
        {
            firstName,
            lastName,
            email,
            password,
            role,
            commissionNo,
            businessName,
            code,
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
            },
        }
    )
}

export function requestPassword(email) {
    return axios.post(
        REQUEST_PASSWORD_URL,
        { email },
        { headers: { 'Content-Type': 'application/json' } }
    )
}

export function requestCode(code, user_id) {
    return axios.post(
        REQUEST_CODE_URL,
        { code, user_id },
        { headers: { 'Content-Type': 'application/json' } }
    )
}

export function requestChangePassword(newPassword, confirmPassword, user_id) {
    return axios.post(
        REQUEST_CHANGE_PASSWORD_URL,
        { newPassword, confirmPassword, user_id },
        { headers: { 'Content-Type': 'application/json' } }
    )
}

export function getUserByToken() {}

export function checkAuth(token) {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    }
    return axios.get(`${MIDDLEWARE_URL}/api/user/checkAuth`, { headers })
}

export function updateProfileCall(user, token) {
    console.log("updateProfileCall");
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    }
    return axios.put(`${MIDDLEWARE_URL}/api/user`, user, { headers })
}

export function updateUserProfileCall(user, token) {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    }
    return axios.put(`${MIDDLEWARE_URL}/api/user`, user, { headers })
}

export function deleteCommisions(deleteCommission, token) {
    const headers = {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
    }
    return axios.post(
        `${MIDDLEWARE_URL}/api/notary/delete-commission`,
        { commissionIds: deleteCommission },
        { headers }
    )
}

export function updatePassportImageCall(user, token) {
    const headers = {
        Authorization: `Bearer ${token}`,
        'content-type': 'x-www-form-urlencoded',
    }
    return axios.post(`${MIDDLEWARE_URL}/api/upload/uploadDoc`, user, {
        headers,
    })
}

export function uploadInsurancePolicyImageCall(user, token) {
    const headers = {
        Authorization: `Bearer ${token}`,
        'content-type': 'x-www-form-urlencoded',
    }
    return axios.post(`${MIDDLEWARE_URL}/api/upload/uploadDoc`, user, {
        headers,
    })
}

export function notaryProfileAvatarCall(user, token) {
    const headers = {
        Authorization: `Bearer ${token}`,
        'content-type': 'x-www-form-urlencoded',
    }
    return axios.post(`${MIDDLEWARE_URL}/api/upload/uploadDoc`, user, {
        headers,
    })
}

export function userProfileAvatarCall(user, token) {
    const headers = {
        Authorization: `Bearer ${token}`,
        'content-type': 'x-www-form-urlencoded',
    }
    return axios.post(`${MIDDLEWARE_URL}/api/upload/uploadDoc`, user, {
        headers,
    })
}

export function deleteDocumentByID(id, token) {
    const headers = {
        Authorization: `Bearer ${token}`,
        'content-type': 'x-www-form-urlencoded',
    }

    return axios.delete(`${MIDDLEWARE_URL}/api/upload/removeProfilePic/${id}`, {
        headers,
    })
}

export function deleteUserDocumentByID(id, token) {
    const headers = {
        Authorization: `Bearer ${token}`,
        'content-type': 'x-www-form-urlencoded',
    }

    return axios.delete(`${MIDDLEWARE_URL}/api/upload/removeProfilePic/${id}`, {
        headers,
    })
}

export function getStripVerificationCall(authToken) {
    const headers = {
        Authorization: `Bearer ${authToken}`,
        'content-type': 'x-www-form-urlencoded',
    }
    return axios.get(`${MIDDLEWARE_URL}/api/account/getAccountLink`, {
        headers,
    })
}
